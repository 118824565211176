import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div className="logo">🔗</div> {/* Aqui você pode usar um símbolo ou logotipo */}
        <nav className="navbar">
          <button className="nav-button">Home</button>
          <button className="nav-button">Quem Somos</button>
          <button className="nav-button">Portfólio</button>
          <button className="nav-button">Contato</button>
        </nav>
      </header>
      <div className="main-content">
        <h1 style={{ fontSize: '4rem' }}>Chasqui - busca de carros</h1> {/* Texto grande no centro */}
      </div>
    </div>
  );
}

export default App;
